<template>
  <div class="row table-sm mr-0 ml-0 p-0">
    <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
      <div class="
          col-7
          p-0
          align-self-center
          font-weight-bold
          d-flex
          align-items-center
          w-reset
          mb-sm-2
        ">
        <h5 class="d-inline mr-2 font-weight-bold">
          {{ $t("message.patient_age_reports") }}
        </h5>
        <crm-refresh @c-click="refresh()"></crm-refresh>
        <div class="text-center d-flex sorddata ml-3">
          <el-input size="mini" :placeholder="$t('message.search')" prefix-icon="el-icon-search"
            v-model="filterForm.search" clearable></el-input>
        </div>
      </div>
    </div>
    <table class="table table-bordered table-hover" v-loading="loadingData">

      <thead>
        <tr>
          <crm-th :column="columns.id" :sort="sort" @c-change="updateSort"></crm-th>
          <crm-th :column="columns.name" :sort="sort" @c-change="updateSort"></crm-th>
          <crm-th :column="columns.count" :sort="sort" @c-change="updateSort"></crm-th>
          <crm-th :column="columns.percentage" :sort="sort" @c-change="updateSort"></crm-th>
          <crm-th :sort="sort" :column="columns.updated_at" @c-change="updateSort"></crm-th>
          <crm-th :sort="sort" :column="columns.created_at" @c-change="updateSort"></crm-th>
        </tr>
        <tr>
          <th v-if="columns.id.show">
            <el-input clearable size="mini" class="id_input" v-model="filterForm.id"
              :placeholder="columns.id.title"></el-input>
          </th>

          <th v-if="columns.name.show">
            <el-input size="mini" v-model="filterForm.name" :placeholder="columns.name.title" clearable>
            </el-input>
          </th>

          <th v-if="columns.count.show">
            <el-input size="mini" v-model="filterForm.count" :placeholder="columns.count.title" clearable>
            </el-input>
          </th>

          <th v-if="columns.percentage.show">
            <el-input size="mini" v-model="filterForm.percentage" :placeholder="columns.percentage.title" clearable>
            </el-input>
          </th>
        </tr>
      </thead>
      <transition-group name="flip-list" tag="tbody">
        <tr v-for="partnerDoctorReport in list" :key="partnerDoctorReport.id">
          <!-- {{partnerDoctorReport}} -->
          <td v-if="columns.id.show">{{ partnerDoctorReport.id }}</td>
          <td v-if="columns.name.show">{{ partnerDoctorReport.name }}</td>
          <td v-if="columns.count.show">
            {{ partnerDoctorReport.count }}
          </td>
          <td v-if="columns.percentage.show">
            {{ partnerDoctorReport.percentage }} %
          </td>
          <!-- <td v-if="columns.updated_at.show">
            {{ partnerDoctorReport.updated_at  }}
          </td>
          <td v-if="columns.created_at.show">
            {{ partnerDoctorReport.created_at  }}
          </td> -->
          <!-- <td v-if="columns.settings.show" class="settings-td">
            <el-button
              @click="showReport(partnerDoctorReport)"
              type="success"
              icon="el-icon-view"
              round
              >{{ $t("message.reports") }}</el-button
            >
          </td> -->
        </tr>
      </transition-group>
    </table>



  </div>
</template>

<script>
import CabintPartnerDoctor from "./components/cabnet-partnerdoctor";
import { mapGetters, mapActions, mapState } from "vuex";
import list from "@/utils/mixins/list";
export default {
  mixins: [list],
  name: "partnerDoctorReportList",

  data() {
    return {
      drawerCreate: false,
      drawerUpdate: false,
      reopenUpdate: false,
      // excel_data: [],
      loadingData: false,
      drawer: false,
      selectedModel: {},
    };
  },
  components: {
    CabintPartnerDoctor,
  },
  computed: {
    ...mapGetters({
      list: "patientAgeReport/list",
      columns: "patientAgeReport/columns",
      pagination: "patientAgeReport/pagination",
      filter: "patientAgeReport/filter",
      sort: "patientAgeReport/sort",
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  async created() { },

  watch: {},
  methods: {
    ...mapActions({
      showModel: "patientAgeReport/show",
      updateList: "patientAgeReport/index",
      updateSort: "patientAgeReport/updateSort",
      updateFilter: "patientAgeReport/updateFilter",
      updateColumn: "patientAgeReport/updateColumn",
      updatePagination: "patientAgeReport/updatePagination",
      editModel: "patientAgeReport/show",
      empty: "patientAgeReport/empty",
      delete: "patientAgeReport/destroy",
      refreshData: "patientAgeReport/refreshData",
    }),
    fetchData() {
      this.loadingData = true;
      const query = { ...this.filter, ...this.pagination, ...this.sort };

      this.updateList(query)
        .then((res) => {
          this.partnerDoctorReport = res.partnerDoctorReport;
          this.loadingData = false;
        })
        .catch((err) => {
          this.loadingData = false;
        });
    },
    refresh() {
      this.refreshData()
        .then((res) => {
          this.filterForm = JSON.parse(JSON.stringify(this.filter));
        })
        .catch((err) => { });
    },
    async edit(model) {
      await this.editModel(model.id)
        .then(async (res) => {
          this.drawerUpdate = true;
        })
        .catch((err) => { });
    },
    async show(model) {
      await this.showModel(model.id)
        .then((res) => {
          this.drawerShow = true;
        })
        .catch((err) => { });
    },
    drawerOpened(ref) {
      this.$refs[ref].afterOpen();
    },
    showReport(model) {
      // this.$loadingCursor('wait');
      // this.showModel({id:model.id})
      // .then(res => {
      // this.$loadingCursor('default');
      this.drawer = true;
      this.selectedModel = model;

      // }).catch(err => {
      //     this.$notify({
      //   title: "Ошибка",
      //   type: "error",
      //   offset: 130,
      //   message: err.error.message,
      // });
      //     this.$loadingCursor('default');
      //     this.drawer = false;
      // });
    },
    handleCurrentChange(val) {
      this.updatePagination({ key: "page", value: val })
        .then((res) => { })
        .catch((err) => { });
    },
    destroyModel(model) {
      this.delete(model.id)
        .then((res) => {
          this.$notify({
            title: 'Ошибка',
            type: "error",
            offset: 130,
            message: res.message
          });
          this.fetchData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    emptyModel() {
      this.empty();
    },
  },
};
</script>
